import { Component, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PerilLovComponent } from '../../common/peril-lov/peril-lov.component';
import { ContractTypeLovDialogComponent } from '../../common/contract-type-lov/contract-type-lov.component';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { forkJoin, Observable } from 'rxjs';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { SecurityService } from 'src/app/services/security.service'; 
import { AgentGroupLovDialogComponent } from '../../common/agent-group-lov/agent-group-lov.component';
@Component({
  selector: 'dealership-standard-rates-maintenance',
  templateUrl: './dealership-standard-rates-maintenance.component.html',
  styleUrls: ['./dealership-standard-rates-maintenance.component.css']
})
export class DealershipStandardRatesMaintenanceComponent implements OnInit, LeaveConfirmation {
  
  datePipe = new DatePipe('en-us');

  addDisabled : boolean = false;
  saveDisabled : boolean = true;
  deleteDisabled : boolean = true;
  tranPosted : boolean = false;
  noLoadingInProcess : boolean = true;
  validated : boolean = false;
  userIDReadOnly : boolean = false;
  isLineSelected : boolean = false;
  pubUserStdRatesCodeField: string = '';
  currentUser: string = this.jsonDataService.retrieveFromStorage('userId');
  rowClickedData: any;
  selectedData : any = null;
  moduleId: string = 'BMM073';
  perilDisabled: boolean = true;
  moduleName: string = '';
  selectedLine: any = {
    LINE_CD: '',
    LINE_NAME: '',
    LINE_FIELD: ''
  };

  selectedAgentGrp: any = {
    AGENT_GRP: '',
    AGENT_GRP_DESC: '',
    ISS_FIELD: ''
  };

  selectedPeril: any = {
    PERIL_CD:'',
    PERIL_LNAME:'',
    PERIL_FIELD:''
  };

  selectedContract: any = {
    CONTRACT_TYPE_CD:'',
    CONTRACT_TYPE_DESC:'',
    CONTRACT_TYPE_FIELD:''
  };
  tempAddedData: any = [];
  tempUpdateData: any = [];

  paramForm = this.formBuilder.group({
    lineCd: [null],
    lineName: [null],
    lineField: [null],
    agentGrpCd: [null],
    agentGrpName: [null],
    agentGrpField: [null],
    contractTypeCd:[null],
    contractTypeName:[null],
    contractTypeField:[null],
    perilCd:[null],
    perilName:[null],
    perilField:[null]
  });
  dealershipStdRatesForm = this.formBuilder.group({
    premiumRate: [null, [Validators.required, Validators.maxLength(13)]],
    activeTag: ['A', [Validators.required]],
    commissionRate: [null, [Validators.required, Validators.maxLength(13)]],
    servicefeeRate: [null, [Validators.required, Validators.maxLength(13)]],
    defaultTag: ['No', [Validators.required]],
    effDateFrom: [new Date(), [Validators.required]],
    effDateTo: [null],
    remarks: [null],
    userID: [null],
    lastUserUpdate: [null]
  });

  constructor(
    private apiCallService: APICallService,
    public _Dialog: MatDialog, 
    private userDetailService: UserDetailsService,
    private formService: FormService, 
    private formBuilder: FormBuilder, 
    private appMessageService: AppMessageService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService
  ) {  
    this.moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddedData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  dealershipStdRatesTblConfig: any = {
    cols: [
      {
        key: "PREM_RT",
        header: "Premium Rate",
        dataType: "rateDecimal"
      },
      {
        key: "COMM_RT",
        header: "Comission Rate",
        dataType: "rateDecimal"
      },
      {
        key: "SERVICE_FEE_RT",
        header: "Service Fee Rate",
        dataType: "rateDecimal"
      },
      {
        key: "EFF_DATE_FROM",
        header: "Effective Date",
        dataType: "date"
      },
      {
        key: "EFF_DATE_TO",
        header: "Expiry Date",
        dataType: "date"
      },
      {
        key: "DEFAULT_TAG",
        header: "Default",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '10%'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }
  ngOnInit() {
  }

  initOnGet(): void {
    try {
      this.apiCallService.refreshNeeded$
            .subscribe(() => {
              this.getAllPubUserStdRates();
          });
      this.getAllPubUserStdRates();
    }
    catch(e) {
    }
  }

  async getAllPubUserStdRates(){
    try{
      let lineCd = this.paramForm.get('lineCd')?.value;
      let agentGrpCd = this.paramForm.get('agentGrpCd')?.value;
      let perilCd = this.paramForm.get('perilCd')?.value;
      let contractTypeCd = this.paramForm.get('contractTypeCd')?.value;

      if (lineCd && agentGrpCd&& perilCd&& contractTypeCd ) {
        const params = {
          queryParams:{
            agentGrpCd: agentGrpCd,
            lineCd: lineCd,
            perilCd: perilCd,
            contractTypeCd: contractTypeCd,
          },
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          type: "MODULE"
        }
        this.dealershipStdRatesTblConfig.loading = true;
        this.apiCallService.getDealershipStandardRates(params).subscribe({
          next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, ()=>{
              this.securityService.hasValidCsrfToken(data, ()=>{
                data = JSON.parse(this.jsonDataService.decrypt(data.response));
                let rawData:any = [];
                let finalData:any = [];
                if(!Array.isArray(data)){
                  rawData.push(data);
                }
                else{
                  rawData = data;
                }
                
                // finalData = this.populateTempData(finalData, rawData);
                if(rawData.length>1){
                  rawData.sort(function(a:any, b:any){
                    let aPremRt = Number(a.PREM_RT);
                    let bPremRt = Number(b.PREM_RT);
                    return aPremRt - bPremRt;
                  });
            
                  for(let item of rawData){
                    if(item.DEFAULT_TAG.toUpperCase()=='Y'){
                      item.DEFAULT_TAG = 'Yes';
                    }else{
                      item.DEFAULT_TAG= 'No';
                    }
                  }
                }
                else if(rawData.length==1){
                  for(let item of rawData){
                    if(item.DEFAULT_TAG.toUpperCase()=='Y'){
                      item.DEFAULT_TAG = 'Yes';
                    }else{
                      item.DEFAULT_TAG= 'No';
                    }
                  }
                }
                this.dealershipStdRatesTblConfig.tblData = rawData;
                this.dealershipStdRatesTblConfig.loading = false;
                this.tempAddedData = [];
                this.tempUpdateData = [];
                this.saveDisabled = this.saveChecker();
              });
              
            })
          },
          error: (e: any) => {
            this.dealershipStdRatesTblConfig.loading = false;
          }
        });
      } else{
        this.dealershipStdRatesTblConfig.loading = true;
        this.dealershipStdRatesTblConfig.tblData = [];
        this.dealershipStdRatesTblConfig.loading = false;
        this.tempAddedData = [];
        this.tempUpdateData = [];
        this.saveDisabled = this.saveChecker();
      }
    }catch(e){
    }
  }

  updateLine() {
    try {
      this.tranPosted = true;
      this.validateUpdate();
      if (this.validated) {
        this.updateFormTable();
      }
    }
    catch(e) {
    }
  }

  updateFormTable() {
    try {
      let fieldPremRt = this.dealershipStdRatesForm.get('premiumRate')?.value;
      let fieldEffDateFrom = new Date(this.dealershipStdRatesForm.get('effDateFrom')?.value);
      let fieldEffDateTo = this.dealershipStdRatesForm.get('effDateTo')?.value?this.datePipe.transform(new Date(this.dealershipStdRatesForm.get('effDateTo')?.value), 'yyyy-MM-dd'): null;
      let tempInsertChecker = this.tempAddedData.find((line: { PREM_RT: any, EFF_DATE_FROM:any }) => 
        +line.PREM_RT == +fieldPremRt&&getDateNum(new Date(line.EFF_DATE_FROM))==getDateNum(fieldEffDateFrom)
      );
      let tempUpdateChecker = this.tempUpdateData.find((line: { PREM_RT: any, EFF_DATE_FROM:any }) => 
        +line.PREM_RT == +fieldPremRt&&getDateNum(new Date(line.EFF_DATE_FROM))==getDateNum(fieldEffDateFrom)
      );
      
      this.tableDataUpdate();
        
      // Adds Updated Data that exists in the database to an object array for updated datas
      if(tempInsertChecker!=null && tempInsertChecker!='') {
        //this.tempUpdateData.push(this.formValueToData());
        tempInsertChecker.AGENT_GRP= this.paramForm.get('agentGrpCd')?.value;
        tempInsertChecker.LINE_CD= this.paramForm.get('lineCd')?.value;
        tempInsertChecker.PERIL_CD= this.paramForm.get('perilCd')?.value;
        tempInsertChecker.CONTRACT_TYPE_CD= this.paramForm.get('contractTypeCd')?.value;
        tempInsertChecker.PREM_RT = +this.dealershipStdRatesForm.get('premiumRate')?.value;
        tempInsertChecker.ACTIVE_TAG = this.dealershipStdRatesForm.get('activeTag')?.value;
        tempInsertChecker.COMM_RT = +this.dealershipStdRatesForm.get('commissionRate')?.value;
        tempInsertChecker.SERVICE_FEE_RT = this.dealershipStdRatesForm.get('servicefeeRate')?.value,
        tempInsertChecker.DEFAULT_TAG = this.dealershipStdRatesForm.get('defaultTag')?.value;
        tempInsertChecker.EFF_DATE_FROM = this.datePipe.transform(new Date(this.dealershipStdRatesForm.get('effDateFrom')?.value), 'yyyy-MM-dd');
        tempInsertChecker.EFF_DATE_TO = fieldEffDateTo;
        tempInsertChecker.REMARKS = this.dealershipStdRatesForm.get('remarks')?.value;
        tempInsertChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.dealershipStdRatesForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempInsertChecker.USER_ID = this.dealershipStdRatesForm.get('userID')?.value;
        tempInsertChecker.MODE = 'I';
      } 
      else if(tempUpdateChecker!=null && tempUpdateChecker!='') {
        tempUpdateChecker.AGENT_GRP= this.paramForm.get('agentGrpCd')?.value;
        tempUpdateChecker.LINE_CD= this.paramForm.get('lineCd')?.value;
        tempUpdateChecker.PERIL_CD= this.paramForm.get('perilCd')?.value;
        tempUpdateChecker.CONTRACT_TYPE_CD= this.paramForm.get('contractTypeCd')?.value;
        tempUpdateChecker.PREM_RT  = +this.dealershipStdRatesForm.get('premiumRate')?.value;
        tempUpdateChecker.ACTIVE_TAG = this.dealershipStdRatesForm.get('activeTag')?.value;
        tempUpdateChecker.COMM_RT = +this.dealershipStdRatesForm.get('commissionRate')?.value;
        tempUpdateChecker.SERVICE_FEE_RT = this.dealershipStdRatesForm.get('servicefeeRate')?.value,
        tempUpdateChecker.DEFAULT_TAG = this.dealershipStdRatesForm.get('defaultTag')?.value;
        tempUpdateChecker.EFF_DATE_FROM = this.datePipe.transform(new Date(this.dealershipStdRatesForm.get('effDateFrom')?.value), 'yyyy-MM-dd');
        tempUpdateChecker.EFF_DATE_TO = fieldEffDateTo;
        tempUpdateChecker.REMARKS = this.dealershipStdRatesForm.get('remarks')?.value;
        tempUpdateChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.dealershipStdRatesForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempUpdateChecker.USER_ID = this.dealershipStdRatesForm.get('userID')?.value;
        tempUpdateChecker.MODE = 'U';
      }
      else {
        this.tempUpdateData.push(this.formValueToData());
      }
      this.saveDisabled = false;
      this.resetFormValues();
    }
    catch(e) {
    }
  }

  tableDataUpdate() {
    try {
      this.dealershipStdRatesForm.patchValue({
        activeTag: this.dealershipStdRatesForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let fieldEffDateTo = this.dealershipStdRatesForm.get('effDateTo')?.value?new Date(this.dealershipStdRatesForm.get('effDateTo')?.value): null;
      let updateIndex = this.dealershipStdRatesTblConfig.tblData.indexOf(this.rowClickedData);
      
      this.dealershipStdRatesTblConfig.tblData[updateIndex] = {
        AGENT_GRP: this.paramForm.get('agentGrpCd')?.value,
        LINE_CD: this.paramForm.get('lineCd')?.value,
        PERIL_CD: this.paramForm.get('perilCd')?.value,
        CONTRACT_TYPE_CD: this.paramForm.get('contractTypeCd')?.value,
        PREM_RT: +this.dealershipStdRatesForm.get('premiumRate')?.value,
        ACTIVE_TAG: this.dealershipStdRatesForm.get('activeTag')?.value,
        COMM_RT: +this.dealershipStdRatesForm.get('commissionRate')?.value,
        SERVICE_FEE_RT: this.dealershipStdRatesForm.get('servicefeeRate')?.value,
        DEFAULT_TAG: this.dealershipStdRatesForm.get('defaultTag')?.value,
        EFF_DATE_FROM: new Date(this.dealershipStdRatesForm.get('effDateFrom')?.value),
        EFF_DATE_TO: fieldEffDateTo,
        REMARKS: this.dealershipStdRatesForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.dealershipStdRatesForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.dealershipStdRatesForm.get('userID')?.value,
        MODE: 'U'
      };
      this.dealershipStdRatesTblConfig.tblData = [...this.dealershipStdRatesTblConfig.tblData];
    }
    catch(e) {
    }
  }

  formValueToData() {
    try {
      let fieldEffDateTo = this.dealershipStdRatesForm.get('effDateTo')?.value?this.datePipe.transform(new Date(this.dealershipStdRatesForm.get('effDateTo')?.value), 'yyyy-MM-dd'): null;
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        AGENT_GRP: this.paramForm.get('agentGrpCd')?.value,
        LINE_CD: this.paramForm.get('lineCd')?.value,
        PERIL_CD: this.paramForm.get('perilCd')?.value,
        CONTRACT_TYPE_CD: this.paramForm.get('contractTypeCd')?.value,
        PREM_RT: this.dealershipStdRatesForm.get('premiumRate')?.value,
        ACTIVE_TAG: this.dealershipStdRatesForm.get('activeTag')?.value,
        COMM_RT: this.dealershipStdRatesForm.get('commissionRate')?.value,
        SERVICE_FEE_RT: this.dealershipStdRatesForm.get('servicefeeRate')?.value,
        DEFAULT_TAG: this.dealershipStdRatesForm.get('defaultTag')?.value,
        EFF_DATE_FROM: this.datePipe.transform(new Date(this.dealershipStdRatesForm.get('effDateFrom')?.value), 'yyyy-MM-dd'),
        ORIG_EFF_DATE_FROM: this.dealershipStdRatesForm.get('effDateFrom')?.value,
        EFF_DATE_TO: fieldEffDateTo,
        REMARKS: this.dealershipStdRatesForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.dealershipStdRatesForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.dealershipStdRatesForm.get('userID')?.value,
        MODE: 'U'
      }
      return formToData;
    }
    catch(e) {
    }
  }
  deleteLine() {
    try {
      let clickData = this.rowClickedData;
      this.dealershipStdRatesTblConfig.tblData = this.dealershipStdRatesTblConfig.tblData.filter(
        function(e: any) {
          return +e.PREM_RT !== +clickData.PREM_RT||getDateNum(new Date(e.EFF_DATE_FROM))!==getDateNum(new Date(clickData.EFF_DATE_FROM));
        }
      );
      this.tempAddedData = this.tempAddedData.filter(
        function(e: any) {

          return +e.PREM_RT !== +clickData.PREM_RT||getDateNum(new Date(e.EFF_DATE_FROM))!==getDateNum(new Date(clickData.EFF_DATE_FROM));
        }
      );
      this.resetFormValues();
    }
    catch(e) {
    }
  }

  insertLine() {
    try {
      this.tranPosted = true;
      this.validateInsert();
      if (this.validated) {
        this.addFormToTable();
      }
    }
    catch(e) {
    }
  }
  addFormToTable() {
    try {
      this.dealershipStdRatesForm.patchValue({
        activeTag: this.dealershipStdRatesForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let fieldEffDateTo = this.dealershipStdRatesForm.get('effDateTo')?.value?this.datePipe.transform(new Date(this.dealershipStdRatesForm.get('effDateTo')?.value), 'yyyy-MM-dd'): null;
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        AGENT_GRP: this.paramForm.get('agentGrpCd')?.value,
        LINE_CD: this.paramForm.get('lineCd')?.value,
        PERIL_CD: this.paramForm.get('perilCd')?.value,
        CONTRACT_TYPE_CD: this.paramForm.get('contractTypeCd')?.value,
        PREM_RT: +this.dealershipStdRatesForm.get('premiumRate')?.value.toUpperCase(),
        COMM_RT: +this.dealershipStdRatesForm.get('commissionRate')?.value,
        SERVICE_FEE_RT: this.dealershipStdRatesForm.get('servicefeeRate')?.value,
        EFF_DATE_FROM: this.datePipe.transform(new Date(this.dealershipStdRatesForm.get('effDateFrom')?.value), 'yyyy-MM-dd'),
        EFF_DATE_TO: fieldEffDateTo,
        ACTIVE_TAG: this.dealershipStdRatesForm.get('activeTag')?.value,
        DEFAULT_TAG: this.dealershipStdRatesForm.get('defaultTag')?.value,
        REMARKS: this.dealershipStdRatesForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.dealershipStdRatesForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.dealershipStdRatesForm.get('userID')?.value,
        MODE: 'I'
      }
      this.dealershipStdRatesTblConfig.tblData.unshift(formToData);
      this.dealershipStdRatesTblConfig.tblData = [...this.dealershipStdRatesTblConfig.tblData];
      this.tempAddedData.push(formToData);
      this.resetFormValues();
    }
    catch(e) {
    }
  }

  saveData(tempChangeData:any[]){
    this.apiCallService.saveDealershipStandardRates(tempChangeData)
    .subscribe({
      next: (response : any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
          this.securityService.hasValidCsrfToken(response, ()=>{
            if (response.status == "SUCCESS" && this.tranPosted) {
              this.openDialog('SUCCESS', 'Details Successfully saved!');
              this.initOnGet();
              this.ngOnInit();
              this.resetFormValues();
              this.tempAddedData = [];
              this.tempUpdateData = [];
              this.saveDisabled = this.saveChecker();
            }  
            else {
              this.initOnGet();
              this.saveDisabled = this.saveChecker();
              this.openDialog('ERROR', 'An error has occured while saving details');
            }
          });
        });
      }, 
      error: () => {
        this.openDialog('ERROR', 'An error has occured while saving details');
      }
    });
  }

  toSave() {
    try {
      this.noLoadingInProcess = false;
      this.formService.showFormLoader(null, "generate-line-form", "Please wait ...", null, null);
      let tempChangeData= this.tempAddedData.concat(this.tempUpdateData);
      if(tempChangeData.length!=0) {
        for(let item of tempChangeData){
          if(item.DEFAULT_TAG.toUpperCase()=='YES'){
            item.DEFAULT_TAG = 'Y';
          }else{
            item.DEFAULT_TAG= 'N';
          }
          item.PREM_RT = item.PREM_RT.toString();
          item.COMM_RT = item.COMM_RT.toString();
          item.SERVICE_FEE_RT = item.SERVICE_FEE_RT.toString();
        }
        this.saveData(tempChangeData);
      } else {
        this.initOnGet();
        this.resetFormValues();
        this.tempAddedData = [];
        this.tempUpdateData = [];
        this.saveDisabled = this.saveChecker();
      }
    }
    catch(e) {
    }
  }
  saveChecker() {
    try {
      if((this.tempAddedData==null || this.tempAddedData=='') && (this.tempUpdateData==null || this.tempUpdateData=='')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  deleteChecker(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedData.filter(function(e: any) {
        return +e.PREM_RT==+ev.PREM_RT&&getDateNum(new Date(e.EFF_DATE_FROM))==getDateNum(new Date(ev.EFF_DATE_FROM));
      });
      if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  perilChecker(){
    try{
      let lineCd = this.paramForm.get('lineCd')?.value;
      if(lineCd!==''&&lineCd!==null){
        return false;
      }
      else{ 
        return true;
      }
    }catch(e){
      return true;
    }
  }
  onRowClick(ev: any) {
    try {    
      if(ev!=null) {
        this.rowClickedData = ev;
        this.dealershipStdRatesForm.get('lineCode')?.disable();
        this.loadFormValues();
        this.saveDisabled = this.saveChecker();
        this.deleteDisabled = this.deleteChecker(ev);
      }
      else {
        this.resetFormValues();
      }
    }
    catch(e) {
    }
  }

  loadFormValues() {
    try {
      let premiumRate: string = '';
      let commisionRate: string= '';
      let servicefeeRate: string = '';
      if(this.rowClickedData.PREM_RT!==''||this.rowClickedData.PREM_RT!==null){
        premiumRate = parseFloat(this.rowClickedData.PREM_RT).toFixed(9);
      }
      if(this.rowClickedData.COMM_RT!==''||this.rowClickedData.COMM_RT!==null){
        commisionRate = parseFloat(this.rowClickedData.COMM_RT).toFixed(9);
      }
      if(this.rowClickedData.SERVICE_FEE_RT!==''||this.rowClickedData.SERVICE_FEE_RT!==null){
        servicefeeRate = parseFloat(this.rowClickedData.SERVICE_FEE_RT).toFixed(9);
      }
      let fieldEffDateTo = this.rowClickedData.EFF_DATE_TO?new Date(this.rowClickedData.EFF_DATE_TO):null;
      this.dealershipStdRatesForm.patchValue({
        premiumRate: premiumRate,
        activeTag: this.rowClickedData.ACTIVE_TAG,
        commissionRate: commisionRate,
        servicefeeRate:servicefeeRate,
        defaultTag: this.rowClickedData.DEFAULT_TAG,
        effDateFrom: new Date(this.rowClickedData.EFF_DATE_FROM),
        effDateTo: fieldEffDateTo,
        remarks: this.rowClickedData.REMARKS,
        userID: this.rowClickedData.USER_ID,
        lastUserUpdate: this.rowClickedData.LAST_USER_UPDATE
      });
      this.paramForm.patchValue({
        
      })
      this.addDisabled = true;
      this.userIDReadOnly = true;
      this.isLineSelected = true;
    }
    catch(e) {
    }
  }

  resetFormValues() {
    try {
      this.selectedData = null;
      // this.paramForm.get('lineCD')?.enable();
      this.dealershipStdRatesForm.patchValue({
        premiumRate: null,
        activeTag: 'A',
        commissionRate: null,
        servicefeeRate: null,
        defaultTag: 'No',
        effDateFrom: new Date(),
        effDateTo: null,
        remarks: null,
        userID: null,
        lastUserUpdate: null
      });

      this.addDisabled = false;
      this.saveDisabled = this.saveChecker();
      this.deleteDisabled = true;
      this.userIDReadOnly = false;
      this.isLineSelected = false;

      this.rowClickedData = '' || null;
    }
    catch (e) {
    console.error(e);
    }
  }

  openLineDialog() {
    try {
      this._Dialog.open(LineLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'LINE',
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedLine = data.content;
          this.paramForm.get('lineCd')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_CD);
          this.paramForm.get('lineName')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_NAME);
          this.paramForm.get('lineField')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_CD + " - " + this.selectedLine.LINE_NAME);
        }
        this.paramForm.get('perilCd')?.setValue(null);
        this.paramForm.get('perilName')?.setValue(null);
        this.paramForm.get('perilField')?.setValue(null);
        this.resetFormValues();
        this.initOnGet();
        this.perilDisabled = this.perilChecker();
      });
    } catch(e) {
    }
  }
  openAgentGrpDialog() {
    try {
      this._Dialog.open(AgentGroupLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedAgentGrp = data.content;
          this.paramForm.get('agentGrpCd')?.setValue(this.selectedAgentGrp.AGENT_GRP==null || this.selectedAgentGrp.AGENT_GRP=='' ? '' : this.selectedAgentGrp.AGENT_GRP);
          this.paramForm.get('agentGrpName')?.setValue(this.selectedAgentGrp.AGENT_GRP==null || this.selectedAgentGrp.AGENT_GRP=='' ? '' : this.selectedAgentGrp.AGENT_GRP_DESC);
          this.paramForm.get('agentGrpField')?.setValue(this.selectedAgentGrp.AGENT_GRP==null || this.selectedAgentGrp.AGENT_GRP=='' ? '' : this.selectedAgentGrp.AGENT_GRP + " - " + this.selectedAgentGrp.AGENT_GRP_DESC);
          this.initOnGet();
        }
        this.resetFormValues();
      });
    } catch(e) {
    }
  }
  openContractDialog() {
    try {
      this._Dialog.open(ContractTypeLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedContract=data.content;
          this.paramForm.get('contractTypeCd')?.setValue(Number(this.selectedContract.CONTRACT_TYPE_CD==null || this.selectedContract.CONTRACT_TYPE_CD=='' ? '' : this.selectedContract.CONTRACT_TYPE_CD));
          this.paramForm.get('contractTypeName')?.setValue(this.selectedContract.CONTRACT_TYPE_CD==null || this.selectedContract.CONTRACT_TYPE_CD=='' ? '' : this.selectedContract.CONTRACT_TYPE_DESC);
          this.paramForm.get('contractTypeField')?.setValue(this.selectedContract.CONTRACT_TYPE_CD==null || this.selectedContract.CONTRACT_TYPE_CD=='' ? '' : this.selectedContract.CONTRACT_TYPE_CD + " - " + this.selectedContract.CONTRACT_TYPE_DESC);
          this.initOnGet();
        }
        this.resetFormValues();
      });
    } catch(e) {
    }
  }
  openPerilDialog() {
    try {
      this._Dialog.open(PerilLovComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'PERIL',
          lineCd: this.paramForm.get('lineCd')?.value,
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedPeril = data.content;
          this.paramForm.get('perilCd')?.setValue(this.selectedPeril.PERIL_CD==null || this.selectedPeril.PERIL_CD=='' ? '' : padLeadingZeros(this.selectedPeril.PERIL_CD, 2));
          this.paramForm.get('perilName')?.setValue(this.selectedPeril.PERIL_CD==null || this.selectedPeril.PERIL_CD=='' ? '' : this.selectedPeril.PERIL_LNAME);
          this.paramForm.get('perilField')?.setValue(this.selectedPeril.PERIL_CD==null || this.selectedPeril.PERIL_CD=='' ? '' : padLeadingZeros(this.selectedPeril.PERIL_CD, 2) + " - " + this.selectedPeril.PERIL_LNAME);
          this.initOnGet();
        }
        this.resetFormValues();
      });
    } catch(e) {
    }
  }
  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-line-form");
      this._Dialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
    }
  }

  populateTempData(finalData:any[], rawData:any[]){
    let lineCd = this.paramForm.get('lineCd')?.value;
    let agentGrpCd = this.paramForm.get('agentGrpCd')?.value;
    let perilCd = this.paramForm.get('perilCd')?.value;
    let contractTypeCd = this.paramForm.get('contractTypeCd')?.value;

    let filteredAddedData: any=[];
    let filteredUpdatedData: any=[];
    if(this.tempUpdateData!=null&&this.tempUpdateData.length!=0){
      filteredUpdatedData = this.tempUpdateData.filter(function(item:any){
        if(item.AGENT_GRP==agentGrpCd&&item.LINE_CD==lineCd&&item.CONTRACT_TYPE_CD==contractTypeCd&&item.PERIL_CD==perilCd){
          finalData = rawData.filter(function(rawDataItem:any){
            if(rawDataItem.AGENT_GRP==agentGrpCd&&rawDataItem.LINE_CD==lineCd&&rawDataItem.CONTRACT_TYPE_CD==contractTypeCd&&rawDataItem.PERIL_CD==perilCd&&rawDataItem.PREM_RT==item.PREM_RT&&rawDataItem.EFF_DATE_FROM==item.EFF_DATE_FROM){
              return false;
            }
            else{
              return true;
            }
          })
          return true;
        }
        else{
          return false;
        }
      })
      if(filteredUpdatedData.length!=0&&filteredUpdatedData!=null){
        finalData = finalData.concat(filteredUpdatedData);
      }
      else{
        finalData = rawData;
      }
    }
    else{
      finalData = rawData;
    }
    
    if(this.tempAddedData!=null&&this.tempAddedData.length!=0){
      filteredAddedData = this.tempAddedData.filter(function(item:any){
        if(item.AGENT_GRP==agentGrpCd&&item.LINE_CD==lineCd&&item.CONTRACT_TYPE_CD==contractTypeCd&&item.PERIL_CD==perilCd){
          return true;
        }
        else{
          return false;
        }
      })
      if(filteredAddedData.length!=0&&filteredAddedData!=null){
        finalData = finalData.concat(filteredAddedData);
      }
    }
    if(finalData.length>1){
      finalData.sort(function(a, b){
        let aPremRt = Number(a.PREM_RT);
        let bPremRt = Number(b.PREM_RT);
        return aPremRt - bPremRt;
      });

      for(let item of finalData){
        if(item.DEFAULT_TAG.toUpperCase()=='Y'){
          item.DEFAULT_TAG = 'Yes';
        }else{
          item.DEFAULT_TAG= 'No';
        }
      }
    }
    else if(finalData.length==1){
      for(let item of finalData){
        if(item.DEFAULT_TAG.toUpperCase()=='Y'){
          item.DEFAULT_TAG = 'Yes';
        }else{
          item.DEFAULT_TAG= 'No';
        }
      }
    }
    return finalData;
  }
  validateInsert() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()){
        setTimeout(()=>{                           //10 secs countdown
          this.formService.hideFormMsg("dealership-standard-rates-error-message");
        }, 10000);
      }
      else if (!this.duplicateCheck(this.dealershipStdRatesTblConfig.tblData)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("dealership-standard-rates-error-message");
        }, 10000);
      }
      else if(!this.defaultTagCheck(this.dealershipStdRatesTblConfig.tblData)){
        setTimeout(()=>{                           
          this.formService.hideFormMsg("dealership-standard-rates-error-message");
        }, 10000);
      }
      else if (this.effDateToCheck()) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("dealership-standard-rates-error-message");
        }, 10000);
      }
      else if(this.rateCheck('premiumRate')){
        setTimeout(()=>{                           
          this.formService.hideFormMsg("dealership-standard-rates-error-message");
        }, 10000);
      }
      else if(this.rateCheck('commissionRate')){
        setTimeout(()=>{                           
          this.formService.hideFormMsg("dealership-standard-rates-error-message");
        }, 10000);
      }
      else if(this.rateCheck('servicefeeRate')){
        setTimeout(()=>{                           
          this.formService.hideFormMsg("dealership-standard-rates-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  requiredFieldCheck() {
    try {
      if(this.paramForm.get('agentGrpCd')?.value==='' || this.paramForm.get('agentGrpCd')?.value===null
        ||this.paramForm.get('lineCd')?.value==='' || this.paramForm.get('lineCd')?.value===null
        ||this.paramForm.get('contractTypeCd')?.value==='' || this.paramForm.get('contractTypeCd')?.value===null
        ||this.paramForm.get('perilCd')?.value==='' || this.paramForm.get('perilCd')?.value===null
        ||this.dealershipStdRatesForm.get('premiumRate')?.value==='' || this.dealershipStdRatesForm.get('premiumRate')?.value===null 
        || this.dealershipStdRatesForm.get('activeTag')?.value==='' || this.dealershipStdRatesForm.get('activeTag')?.value===null 
        || this.dealershipStdRatesForm.get('commissionRate')?.value==='' || this.dealershipStdRatesForm.get('commissionRate')?.value===null
        || this.dealershipStdRatesForm.get('servicefeeRate')?.value==='' || this.dealershipStdRatesForm.get('servicefeeRate')?.value===null
        || this.dealershipStdRatesForm.get('effDateFrom')?.value==='' || this.dealershipStdRatesForm.get('effDateFrom')?.value===null) {
        this.formService.showFormMsg("dealership-standard-rates-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
   }
    catch (e) {
    console.error(e);
      return false;
    }
  }
  validateUpdate() {
    try {
      this.validated = false;
      let pubUserStdRatesPremiumRate = parseFloat(this.dealershipStdRatesForm.get('premiumRate')?.value);
      let pubUserStdRatesEffDateFrom= getDateNum(new Date(this.dealershipStdRatesForm.get('effDateFrom')?.value));
      let filteredTblData = this.dealershipStdRatesTblConfig.tblData.filter(function(e:any){
        let effDateFrom = getDateNum(new Date(e.EFF_DATE_FROM));
        let ePremRT = parseFloat(e.PREM_RT);
        if(ePremRT!=pubUserStdRatesPremiumRate||effDateFrom!=pubUserStdRatesEffDateFrom){
          return true
        }
        else{
          return false
        }
      })
      if (!this.requiredFieldCheck()){
        setTimeout(()=>{                          
          this.formService.hideFormMsg("dealership-standard-rates-error-message");
        }, 10000);
      }
      else if (!this.duplicateCheck(filteredTblData)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("dealership-standard-rates-error-message");
        }, 10000);
      }
      else if(!this.defaultTagCheck(filteredTblData)){
        setTimeout(()=>{                           
          this.formService.hideFormMsg("dealership-standard-rates-error-message");
        }, 10000);
      }
      else if (this.effDateToCheck()) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("dealership-standard-rates-error-message");
        }, 10000);
      }
      else if(this.rateCheck('commissionRate')){
        setTimeout(()=>{                           
          this.formService.hideFormMsg("dealership-standard-rates-error-message");
        }, 10000);
      }
      else if(this.rateCheck('servicefeeRate')){
        setTimeout(()=>{                           
          this.formService.hideFormMsg("dealership-standard-rates-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }
  duplicateCheck(tblData:any) {
    try {
      let fieldPremiumRate = parseFloat(this.dealershipStdRatesForm.get('premiumRate')?.value);
      let fieldEffDateToValue = this.dealershipStdRatesForm.get('effDateTo')?.value;
      let fieldEffDateFrom= getDateNum(new Date(this.dealershipStdRatesForm.get('effDateFrom')?.value));
      let fieldEffDateTo= getDateNum(new Date(fieldEffDateToValue))
      this.pubUserStdRatesCodeField = tblData.filter(function(e: any) {
        let effDateFrom = getDateNum(new Date(e.EFF_DATE_FROM));
        let effDateTo = getDateNum(new Date(e.EFF_DATE_TO));
        let ePremRT = parseFloat(e.PREM_RT);
        if(ePremRT!==fieldPremiumRate){
          return false
        }
        if(fieldEffDateToValue==''||fieldEffDateToValue==null){
          if(e.EFF_DATE_TO==''||e.EFF_DATE_TO==null){
            return true
          }
          return fieldEffDateFrom<=effDateTo;
        }
        if(e.EFF_DATE_TO==''||e.EFF_DATE_TO==null){
          return effDateFrom<=fieldEffDateTo;
        }
        if(fieldEffDateFrom>=effDateFrom&&fieldEffDateTo<=effDateTo){
            return true;
        }
        if(fieldEffDateFrom<=effDateFrom&&fieldEffDateTo>=effDateTo){
          return true;
        }
        if(fieldEffDateFrom<=effDateFrom&&fieldEffDateTo>=effDateFrom){
          return true;
        }
        if(fieldEffDateFrom<=effDateTo&&fieldEffDateTo>=effDateTo){
          return true;
        }
        return false;
      });
      if(this.pubUserStdRatesCodeField=='' || this.pubUserStdRatesCodeField==null) {
        return true;
      } else {
        this.formService.showFormMsg("dealership-standard-rates-error-message", 'Unable to add record. Premium rate within the entered effectivity period already exists.', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  } 
  defaultTagCheck(tblData:any){
    try {
      let fieldDefaultTag = this.dealershipStdRatesForm.get('defaultTag')?.value;
      let fieldEffDateToValue = this.dealershipStdRatesForm.get('effDateTo')?.value;
      let fieldEffDateFrom= getDateNum(new Date(this.dealershipStdRatesForm.get('effDateFrom')?.value));
      let fieldEffDateTo= getDateNum(new Date(fieldEffDateToValue))
      this.pubUserStdRatesCodeField = tblData.filter(function(e: any) {
        let effDateFrom = getDateNum(new Date(e.EFF_DATE_FROM));
        let effDateTo = getDateNum(new Date(e.EFF_DATE_TO));
        if(fieldDefaultTag!=='Yes'||e.DEFAULT_TAG!='Yes'){
            return false;
        }
        if(effDateFrom===fieldEffDateFrom){
          return true;
        }
        if(fieldEffDateToValue==''||fieldEffDateToValue==null){
          if(e.EFF_DATE_TO==''||e.EFF_DATE_TO==null){
            return true
          }
          return fieldEffDateFrom<=effDateTo;
        }
        if(e.EFF_DATE_TO==''||e.EFF_DATE_TO==null){
          return effDateFrom<=fieldEffDateTo;
        }
        if(fieldEffDateFrom>=effDateFrom&&fieldEffDateTo<=effDateTo){
          return true;
        }
        if(fieldEffDateFrom<=effDateFrom&&fieldEffDateTo>=effDateTo){
          return true;
        }
        if(fieldEffDateFrom<=effDateFrom&&fieldEffDateTo>=effDateFrom){
          return true;
        }
        if(fieldEffDateFrom<=effDateTo&&fieldEffDateTo>=effDateTo){
          return true;
        }
        else{
          return false;
        }
      });
      if(this.pubUserStdRatesCodeField=='' || this.pubUserStdRatesCodeField==null) {
        return true;
      } else {
        this.formService.showFormMsg("dealership-standard-rates-error-message", 'Unable to add record. Default rate for the effectivity period already exists. Only one record should be tagged as default under the same period of effectivity.', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }
  effDateToCheck(){
    try{
      let pubUserStdRatesEffDateFrom= new Date(this.dealershipStdRatesForm.get('effDateFrom')?.value).getTime();
      if(this.dealershipStdRatesForm.get('effDateTo')?.value!==''&&this.dealershipStdRatesForm.get('effDateTo')?.value!==null){
        let pubUserStdRatesEffDateTo= new Date(this.dealershipStdRatesForm.get('effDateTo')?.value).getTime();
        if(pubUserStdRatesEffDateTo<pubUserStdRatesEffDateFrom){
          this.formService.showFormMsg("dealership-standard-rates-error-message", 'Unable to add record. Expiry Date must not be earlier than Effective Date', "E");
          return true
        }
        else{
          return false
        }
      }
      else{
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }
  rateCheck(fieldName:string){
    let rate = parseFloat(this.dealershipStdRatesForm.get(fieldName)?.value);
    let fieldRate = this.dealershipStdRatesForm.get(fieldName)?.value;
    let fieldLabel = '';
    if(fieldName=='premiumRate'){
      fieldLabel = 'Premium Rate'
    }
    else if(fieldName=='commissionRate'){
      fieldLabel = 'Commission Rate'
    }
    else if(fieldName=='servicefeeRate'){
      fieldLabel = 'Service Fee Rate'
    }
    if(fieldRate===''||fieldRate===null){
      return false
    }
    if(rate<0){
      this.formService.showFormMsg("dealership-standard-rates-error-message", 'Invalid value in '+fieldLabel+' field. The value entered is a negative number', "E");
      return true;
    }
    if(rate<1000){
      return false
    }
    else{
      this.formService.showFormMsg("dealership-standard-rates-error-message", 'Invalid value in '+fieldLabel+' field. The value entered exceeded the maximum limit', "E");
      return true
    }
  }

  rateFormat(fieldName: string) {
    console.log("rateFormat triggered for:", fieldName);

    let fieldValue = this.dealershipStdRatesForm.get(fieldName)?.value;
    console.log("Original Value:", fieldValue);

    if (fieldValue !== '' && fieldValue !== null && fieldValue !== undefined) {
        let numericValue = typeof fieldValue === 'string' ? fieldValue.replace(/,/g, '') : fieldValue;
        
        let fieldFloat = parseFloat(numericValue);
        if (isNaN(fieldFloat)) {
            console.log("Invalid number, resetting field.");
            this.dealershipStdRatesForm.get(fieldName)?.setValue('');
        } else {
            let fieldRate = fieldFloat.toFixed(9);  // Keeps 9 decimal places
            console.log("Formatted Value:", fieldRate);
            this.dealershipStdRatesForm.get(fieldName)?.setValue(fieldRate);
        }
    }
  }
  datePattern(ev: any) {
    try{
      //let inputChar = String.fromCharCode(event.charCode)
      if (ev.target.value.length<4) {
        let firstPattern = /^\d{2}\/$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,2);
        }
      } else if(ev.target.value.length<7){
        let firstPattern = /^\d{2}\/\d{2}\/$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,5);
        }
      } else if(ev.target.value.length>6&&ev.target.value.length<11){
        let firstPattern = /^\d{2}\/\d{2}\/\d+$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,ev.target.value.length-1);
        }
      } else if(ev.target.value.length==10){
        let firstPattern = /^\d{2}\/\d{2}\/\d{4}$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,ev.target.value.length-1);
        }
      } else{
        ev.target.value = ev.target.value.substring(0,ev.target.value.length-1);
      }
    }
    catch(e) {
    }
  }
}
function getDateNum(dateInput:Date){
  if(dateInput==null){
    return 0;
  }
  let year = dateInput.getFullYear();
  let month = dateInput.getMonth()+1;
  let day = dateInput.getDate();
  let resultString = year+""+padLeadingZeros(month, 2)+""+padLeadingZeros(day, 2);
  
  let result = parseInt(resultString);
  return result;
}
function padLeadingZeros(num:number, size:number) {
  let sNum = num+"";
  while (sNum.length < size) sNum = "0" + sNum;
  return sNum;
}