import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private apiCallService: APICallService,
        private jsonDataService: JsonDataService,
        private userDetailsService: UserDetailsService,
        private appMessageService: AppMessageService,
        private formService: FormService,
        private securityService: SecurityService
    ) {}

    showPasswordValue: boolean = false;
    showOtpForm: boolean = false;
    userDetails: any = {};

    ngOnInit(): void {
        this.userDetailsService.resetUserDetails();
    }

    loginDetails: FormGroup = this.formBuilder.group({
        un: ["", [Validators.required]],
        pw: ["", [Validators.required]],
    })

    goToForgot(): void {
        this.router.navigate(["home/forgot"]);
    }

    login() {
        this.formService.hideFormMsg("loginFormMsg");
        if (this.loginDetails.valid) {
            this.formService.showFormLoader(null, "login-form", "Logging in.<br>Please wait ...", null, null);
            
            this.apiCallService.login(
                    {
                        ...this.loginDetails.value,
                        localStorage: localStorage
                    }
                ).subscribe((response: any) => {
                //this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status === "SUCCESS") {
                        this.securityService.hasValidCsrfToken(response, () => {
                            const content = JSON.parse(this.jsonDataService.decrypt(response.response));
                            sessionStorage.setItem("rkc", "1");

                            this.formService.hideFormLoader("login-form");

                            if(content.userAuth?.withOTP == 'Y') {
                                this.userDetails = content; //.userAuth;
                                this.userDetails.userAuth.name = content.name;
                                this.userDetails.userAuth.userId = content.userId;
                                this.showOtpForm = true;

                                this.userDetails.userAuth['hideOtpForm'] = () => {
                                    this.showOtpForm = false;
                                };

                                this.userDetails.userAuth['proceedLogin'] = (content: any) => {
                                    return this.proceedLogin(content);
                                };
                            } else {
                                // if (["SUCCESS", "INITIAL"].indexOf(content.status) >= 0) {
                                //     sessionStorage.setItem("bssid", content.bssid);
                                //     // sessionStorage.setItem("rkc", "1");
                                //     sessionStorage.setItem("mdls", this.jsonDataService.encrypt(content.modules));
                                // }
    
                                // if (content.status === "SUCCESS") {
                                //     this.appMessageService.showAppMessage("Logged in successfully", "success");
                                //     this.userDetailsService.setUserDetails(
                                //         "S",
                                //         content.userId,
                                //         content.name,
                                //         content.email,
                                //         content.expiry,
                                //         content.windowId
                                //     );
                                //     this.router.navigate(["dashboard"]);
                                // } else if (content.status == "INITIAL") {
                                //     this.userDetailsService.setUserId(content.userId);
                                //     this.router.navigate(["home/reset"]);
                                // } else {
                                //     this.formService.showFormMsg("loginFormMsg", content.message, "E");
                                //     this.clearFields();
                                //     setTimeout(() => {
                                //         this.formService.hideFormMsg("loginFormMsg")
                                //     }, 5000);
                                // }

                                this.proceedLogin(content);
                            }
                        });
                    } else {
                        this.formService.showFormMsg("loginFormMsg", response.message, "E");
                    }
                //});
            });
        } else {
            this.formService.showFormMsg("loginFormMsg", "Please fill up all the required fields", "E");
        }
    }

    proceedLogin(content: any) {
        if (["SUCCESS", "INITIAL"].indexOf(content.status) >= 0) {
            sessionStorage.setItem("bssid", content.bssid);
            sessionStorage.setItem("mdls", this.jsonDataService.encrypt(content.modules));
        }

        if (content.status === "SUCCESS") {
            this.appMessageService.showAppMessage("Logged in successfully", "success");
            this.userDetailsService.setUserDetails(
                "S",
                content.userId,
                content.name,
                content.email,
                content.expiry,
                content.windowId
            );
            this.router.navigate(["dashboard"]);
        } else if (content.status == "INITIAL") {
            this.userDetailsService.setUserId(content.userId);
            this.router.navigate(["home/reset"]);
        } else {
            this.formService.showFormMsg("loginFormMsg", content.message, "E");
            this.clearFields();
            setTimeout(() => {
                this.formService.hideFormMsg("loginFormMsg")
            }, 5000);
        }
    }

    clearFields() {
        this.loginDetails.patchValue({
            un: "",
            pw: ""
        })
    }
}
